import $ from 'jquery';

class Hover {
    constructor(el) {
        const $el = $(el);
        this.image = $el.data('image');
        this.$elImage = $('.js-hover-image');
        this.$bgImage = $('.js-bg-image');

        setTimeout(() => {
            const images = new Image();
            images.src = this.image;
        }, 500);

        $el.mouseover((e) => {
            this.$elImage.addClass('show');
            this.$bgImage.css('background-image', 'url(' + this.image + ')');

            $(window).mousemove(function (e) {
                $('.js-hover-image').css({left: e.pageX + 20, top: e.pageY + 20});
            });
        }).mouseleave(() => {
            this.$elImage.removeClass('show');
        });

        $el.mousedown(() => {
            this.$elImage.addClass('show');
            this.$bgImage.css('background-image', 'url(' + this.image + ')');
        });
    }
}

export default Hover;
