//TODO: update to this https://codepen.io/fghtech/pen/NWJvWmv
// using swiper.js

export default class Slider {
  constructor(selector) {
    // Check if selector exists before initializing
    if (selector && selector.length) {
      this.init(selector[0]); // Pass the DOM element, not jQuery object
    }
  }

  init(element) {
    const swiper = new Swiper(element, {

      loop: true,
      freeMode: true,
      autoplay: {
        delay: 0,
        pauseOnMouseEnter:true,
        disableOnInteraction:false,
      },
      loopAddBlankSlides:true,
      cssMode:true,
      slidesPerView: 4,
      speed: 5000,
      grabCursor: true,
      loopAdditionalSlides: 4,
    });
  }
}
