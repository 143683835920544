import $ from "jquery";
import CommentForm from '../components/CommentForm'
import QuestionTemplate from './question.twig';

const MINIMUM_TIMEOUT_MS = 2500;
const MINIMIZE_ANIMATION_TIME_MS = 250;

export default class ChatPopup {
    chatMessageList = [];

    constructor($chatElem) {
        this.$chatElem = $chatElem;
        this.$chatMessageContainer = this.$chatElem.find('.js-popup-results');
        this.init();
    }

    init() {
        this.open = true;
        this.extractStyle();

        this.$chatElem
            .find('.popup-btn-close')
            .on('click', () => {
                this.minimizePopup();
            });

        this.$chatElem
            .find('.popup-btn-full-width')
            .on('click', () => {
                this.maximizePopup();
            });

        new CommentForm($('#commentform'));

        //get 50 latest comments from wordpress and render them to be displayed
        $.get('/wp-json/wp/v2/comments?per_page=50&post=75')
            .then(data => {
                data.forEach(comment => {
                    comment.isOwn = comment['author_name'] === this.userId;
                    let renderedQuestion = QuestionTemplate({comment: comment});
                    this.chatMessageList.push(renderedQuestion);
                });
                this.showQuestion();
            });
    }

    maximizePopup() {
        this.$chatElem.removeClass('hide');
        this.$chatElem.parent().removeClass('hide');
        this.$chatElem.css({height: this.currentStyle.height, transform: this.currentStyle.transform});
        this.$chatElem.animate({
                top: this.currentStyle.top,
                left: this.currentStyle.left
            }, MINIMIZE_ANIMATION_TIME_MS,
            () => {
                this.$chatElem.css(this.currentStyle)
            });
        return false;
    }

    minimizePopup() {
        this.extractStyle();
        this.$chatElem.addClass('hide');
        this.$chatElem.parent().addClass('hide');
        this.$chatElem.css({
            position: 'fixed',
            bottom: this.currentStyle.bottom,
            right: this.currentStyle.right,
            left: 'initial',
            top: 'initial',
        });
        this.$chatElem.animate({
            bottom: 0,
            right: 0,
            left: 'initial',
            top: 'initial',
        }, MINIMIZE_ANIMATION_TIME_MS);
    }

    extractStyle() {
        this.currentStyle = {
            top: this.$chatElem.position().top,
            left: this.$chatElem.position().left,
            right: window.innerWidth - (this.$chatElem.position().left + this.$chatElem.outerWidth()),
            bottom: window.innerHeight - (this.$chatElem.position().top + this.$chatElem.outerHeight()),
            transform: this.$chatElem.css('transform'),
            height: (this.$chatElem.css('height') / $(window).height()) * 100 + '%'
        };
    }

    showQuestion() {
        const chatmessage = this.chatMessageList.pop();
        if (chatmessage) {
            this.$chatMessageContainer.append(chatmessage);
            ChatPopup.incrementCommentCount();
            this.$chatMessageContainer.animate({scrollTop: this.$chatMessageContainer[0].scrollHeight}, 1000);
            ChatPopup.shuffleArray(this.chatMessageList);
            setTimeout(
                this.showQuestion.bind(this), (Math.random() * 5000) + MINIMUM_TIMEOUT_MS
            )
        }
    }

    static incrementCommentCount() {
        let $commentCount = $('.js-comments-number');
        let commentCount = $commentCount.html();
        $commentCount.html(++commentCount);
    }

    static shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    static generateID() {
        return '_' + Math.random().toString(36).substr(2, 9);
    };
}
