import $ from "jquery";
import * as basicScroll from 'basicscroll'

export default class Fragments {
    constructor() {
        let $fragmentElems = $('.js-fragment');
        const fragments = [];
        const positionFrag =[
          'top-middle',
          'top-bottom',
          'middle-top',
          'middle-bottom',
          'bottom-top',
          'bottom-middle',
        ];

        let positionY =[
            '2%',
            '15%',
            '30%',
            '50%',
            '70%',
            '90%',
        ];

        let shuffledPositionY = this.shuffle(positionY);

        $.each($fragmentElems, (index, elem) => {
            let fromFrag = positionFrag[Math.floor(Math.random() * positionFrag.length)];
            let toFrag = positionFrag[Math.floor(Math.random() * positionFrag.length)];

            $(elem).css({
               'top' : shuffledPositionY[index],
               'left' : Math.floor(Math.random() * 80) + 1 + '%',
                'opacity' : 1
            });

            fragments.push(basicScroll.create({
                elem: elem,
                from: 'top-top',
                to: 'bottom-bottom',
                direct: true,
                props: {
                    '--tY': {
                        from: Math.floor(Math.random() * 25) + 1 + '%',
                        to: Math.floor(Math.random() * 25) + 1 + '%'
                    },
                    '--tX': {
                        from: Math.floor(Math.random() * 25) + 1 + '%',
                        to: Math.floor(Math.random() * 25) + 1 + '%'
                    },
                    '--r': {
                        from: Math.floor(Math.random() * -5) + 1 + 'deg',
                        to: Math.floor(Math.random() * 5) + 1 + 'deg'
                    }
                }
            }));
        });

        fragments.forEach((fragment) => {
          fragment.start();
        });
    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    shuffle(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }
}
