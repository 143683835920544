import $ from "jquery";
import ChatPopup from "./ChatPopup";

export default class CommentForm {
    constructor(formElem$) {
        this.formElem$ = formElem$;

        if (localStorage.hasOwnProperty("userID")) {
            this.userId = localStorage.getItem("userID");
            this.formElem$.find('#author').val(this.userId);
        }
        this.init();
    }

    init() {
        let $formErrorMessagBox = $('.js-form-errors');

        this.formElem$.submit(() => {
            $formErrorMessagBox.html('');
            this.userId= localStorage.setItem("userID",this.formElem$.find('#author').val());
            const formdata = this.formElem$.serialize();
            const formURL = this.formElem$.attr('action');
            this.formElem$.find('#comment').val('');
            this.formElem$.find('.loading').addClass('show');
            this.formElem$.find('button').removeClass('show');

            $.ajax({
                type: 'post',
                url: formURL,
                data: formdata,
                error: (XMLHttpRequest, textStatus, errorThrown) => {
                    console.log(XMLHttpRequest)
                    console.log(textStatus)
                    if (errorThrown === 'Conflict') {
                        $formErrorMessagBox.html(`You've already asked that`);
                    } else {
                        $formErrorMessagBox.html(`Please wait a while before asking your next question`);
                    }
                    this.formElem$.find('.loading').removeClass('show');
                    this.formElem$.find('button').addClass('show');
                },
                success: (data, textStatus) => {
                    if (data === "success" || textStatus === "success") {
                        let resultContainer$ = $('.js-popup-results');
                        ChatPopup.incrementCommentCount();
                        resultContainer$.append(data);
                        resultContainer$.animate({scrollTop: resultContainer$[0].scrollHeight}, 1000);
                        this.formElem$.find('.loading').removeClass('show');
                        this.formElem$.find('button').addClass('show');
                    }
                }
            });

            return false;
        });
    }
}
