import $ from "jquery";
import Marquee from "../helpers/marquee";
import MobileDetect from 'mobile-detect';

export default class ReferencePopup {
    constructor(sourceElem$) {
        this.sourceElem$ = sourceElem$;
        this.md = new MobileDetect(window.navigator.userAgent);

        this.init();
    }

    init() {
        this.source_id = this.sourceElem$.data('refId');
        this.popupElem$ = $(`.js-popup-reference[data-ref-source='${this.source_id}']`);

        this.popupElem$.find('.popup-btn-close').on('click', () => {
            this.popupElem$.addClass('hide');

            // make sure the video in the iframe is stopped when the popup is closed
            const iframe = this.popupElem$.find('iframe');
            if (iframe) {
                this.popupElem$.find('.embed-container').html();
                setTimeout(() => {
                    this.popupElem$.find('.embed-container').html(iframe);
                }, 250)
            }
        });


        this.sourceElem$.on('click', () => {
            this.popupElem$.removeClass('hide');
            this.popupElem$.css('z-index', global.zindex_count++);

            if (!this.md.mobile()|| $(window).width() > 768) {
                this.setPosition();
            }

            new Marquee(this.popupElem$.find('.marquee-label'));
        });
    }

    setPosition (){
        this.popupElem$.css('top', Math.floor(Math.random() * 70) + 1 + '%');
        this.popupElem$.css('right', Math.floor(Math.random() * 80) + 1 + '%');
    }
}
