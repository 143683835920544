import $ from "jquery";

class Marquee {
    constructor($el) {
        this.lastScrollPos = 0;
        let progress = 1,
            speed = 1,
            container = $el.find('.marquee-inner'),
            content = $el.find('.marquee-inner > *'),
            elWidth = content.outerWidth();

        $el.each(function (index) {

            //Get total width
            let clone = content.clone();
            container.append(clone);

            function loop() {
                progress = progress - speed;

                if (progress <= elWidth * -1) {
                    progress = 0;
                }

                container.css('transform', 'translateX(' + progress + 'px)');

                window.requestAnimationFrame(loop);
            }

            requestAnimationFrame(loop)
        });
    }
}

export default Marquee;
