import $ from "jquery";
import Slider from '../helpers/slider';

export default class Exploration {
    constructor(injector) {
        this.injector = injector;
        this.init();
    }

    init() {
        new Slider();
    }
}
