import GeneralComponents from './helpers/generalComponents'
import HasClass from './helpers/hasClass';
import Home from './pages/home';
import Exploration from './pages/exploration';
import Hover from './helpers/hover';
import Research from "./pages/research";

const body = new HasClass('body');

export default class App {
    constructor() {
        this.injector = {app: this};
    }

    start() {
        new GeneralComponents();

        $('.js-image-hover').each(function () {
            new Hover(this);
        });

        body.has('home', () => {
            new Home(this.injector);
        });

        body.has('tax-exploration', () => {
            new Exploration(this.injector);
        });

        body.has('single-research', () => {
            new Research(this.injector);
        });
    }
}
