import $ from 'jquery';

export default class Nav {
    constructor() {
        $('.navbar-burger').on('click', () => $('.navbar-burger, .navbar').toggleClass('is-active'));

        $('body').on('click', (e) => {
            if($(e.target).closest('.navbar').length == 0) {
                let opened = $('.navbar').hasClass('is-active');
                if ( opened === true ) {
                  $('.navbar').removeClass('is-active');
                }
            }
        })
    }
}
