import $ from "jquery";

export default class MailChimp {
    constructor() {
        let $form = $('#mc-embedded-subscribe-form');

        $('form input[type="button"]').bind('click', function (e) {
            if (e) e.preventDefault();

            $('#mc-embedded-subscribe').val('...');
            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                cache: false,
                contentType: 'application/json; charset=utf-8',
                xhrFields: {
                    withCredentials: true
                },
                dataType: 'jsonp',
                success: function (data) {
                    if (data.result === 'success') {
                        let newsletterBtn = $('.newsletter-btn').data("translation");
                        $('#mc-embedded-subscribe').val(newsletterBtn);
                        $('.js-subscribe-result').show();
                        $('.js-subscribe-emailL').val('<p>' + data.msg + '</p>');
                    } else {
                        $('.js-subscribe-email').css('borderColor', '#ff8282');
                        $('.js-subscribe-result-error').css('color', '#ff8282');
                        $('.js-subscribe-result-error').show();
                        $('.js-subscribe-result-error').html('<p>' + data.msg + '</p>');
                    }
                }
            })
        });
    }
}
