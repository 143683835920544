import ScrollReveal from "scrollreveal";

export default class Reveal {
    constructor() {
        ScrollReveal().reveal('.content-block', {
            distance: "10px",
            duration: 250,
            easing: 'ease-in'
        });
    }
}
