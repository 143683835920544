import $ from 'jquery';
import Drag from '../helpers/drag';
import Nav from '../helpers/nav';
import Marquee from '../helpers/marquee';
import Sticky from '../helpers/sticky';
import MailChimp from "../components/mailChimp";
import Fragments from "../components/fragments";
import Reveal from "../components/reveal";

export default class GeneralComponents {
    constructor() {
        this.start();
    }

    start() {
        new Drag();
        new Nav();
        new Marquee($('.marquee-top'));
        new Sticky();
        new MailChimp();
        new Fragments();
        new Reveal();
    }
}
