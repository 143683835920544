import $ from 'jquery';

export default class HasClass {
    constructor(selector) {
        this.$ = $(selector);
    }

    has(className, cb) {
        if (this.$.hasClass(className)) {
            cb();
        }
    }
}
