import $ from 'jquery';
import Draggabilly from 'draggabilly';
import MobileDetect from 'mobile-detect';

export default class Drag {
    constructor() {
        this.md = new MobileDetect(window.navigator.userAgent);

        if (!this.md.mobile()) {
            let gravityElementsBox = $('.js-draggable');
            const draggies = [];
            $.each(gravityElementsBox, (i, node) => {
                let draggie = new Draggabilly(node, {});
                draggies.push(draggie);
            });
        }
    }
}
