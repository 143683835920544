import Slider from '../helpers/slider';
import ChatPopup from "../components/ChatPopup";
import $ from "jquery";
import HomePagePopup from "../components/HomePagePopup";
import MobileDetect from "mobile-detect/mobile-detect";

const MINIMUM_TIME_ELAPSED = 1000 * 60 * 60; // 1 hour

export default class Home {
    constructor(injector) {
        this.injector = injector;
        this.md = new MobileDetect(window.navigator.userAgent);

        this.init();
    }

    init() {
        this.chatpopup = new ChatPopup($('.js-chat-popup'));
        
        // Initialize slider only if the element exists
        const sliderElement = $('.swiper-container');
        if (sliderElement.length) {
            new Slider(sliderElement);
        }

        if (localStorage.hasOwnProperty("popupsShown")) {
            this.popupsShown = new Date(JSON.parse(localStorage.getItem("popupsShown")));
            let currentTime = new Date();
            const timeSinceLastShownMS = currentTime.getTime() - this.popupsShown.getTime();
            if ((MINIMUM_TIME_ELAPSED - timeSinceLastShownMS) < 0) {
                this.popupsShown = new Date();
                localStorage.setItem("popupsShown", JSON.stringify(this.popupsShown));
                this.showPopups();
                this.showChatPopup();
            } else {
                this.showChatPopup(true);
            }
        } else {
            this.popupsShown = new Date();
            localStorage.setItem("popupsShown", JSON.stringify(this.popupsShown));
            this.showPopups();
            this.showChatPopup();
        }
    }

    showPopups() {
        if (!this.md.mobile()) {
            this.$homePopups = $('.js-home-popup');
            if (this.$homePopups) {
                $.each(this.$homePopups, (index, elem) => {
                    const popup = new HomePagePopup($(elem));
                    setTimeout(() => {
                        popup.show()
                    }, (Math.random() * 5000) + 2000);
                })
            }
        }
    }

    showChatPopup(instantAndMinimized = false) {
        this.$homePopup = $('.js-home-popup-chat');
        if (this.$homePopup) {
            const popup = new HomePagePopup(this.$homePopup);
            if (!instantAndMinimized && !this.md.mobile()) {
                setTimeout(() => {
                    popup.show();
                }, (Math.random() * 5000) + 2000);
            } else {
                popup.show();
                this.chatpopup.minimizePopup();
            }
        }
    }
}
