import $ from 'jquery';

window.$ = $;
window.jQuery = window.$;
import App from './app.js';
import buggyfill from "viewport-units-buggyfill";

$(document).ready(() => {
    let app = new App();
    buggyfill.init();

    app.start();
});
