import $ from "jquery";
import ReferencePopup from "../components/ReferencePopup";

global.zindex_count = 99;

export default class Research {
    constructor(injector) {
        this.injector = injector;
        this.init();
    }

    init() {
        this.referenceSources = $(document.body).find('.entry-content').find('.js-reference-link');
        if (this.referenceSources && this.referenceSources.length > 0) {
            $.each(this.referenceSources, (index, elem) => {
                new ReferencePopup($(elem))
            });
        }
    }
}
