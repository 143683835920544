import $ from "jquery";

class Sticky {
    constructor() {
        //Smooth Scrolling Using Navigation Menu
        $('.navbar-item').on('click', function (e) {
            e.preventDefault();

            let navigation = $(this).attr('href');

            if ($('body').hasClass('home')) {
                $('html,body').animate({
                    scrollTop: $(navigation).offset().top - 100
                }, 500);

                $('.navbar-burger, .navbar').removeClass('is-active');
            } else {
                window.location.href = BASE_URL + '/' + navigation;
            }
        });
    }
}

export default Sticky;
